import { i as isSSR } from './element-d46853d9.js';

/* eslint-disable dot-notation */
const mozRaf = !isSSR() && window['mozRequestAnimationFrame'] && window['mozCancelRequestAnimationFrame']; // Firefox 5 ships without cancel support
const browserSupportsRaf = !isSSR() &&
  (window.requestAnimationFrame ||
    window['webkitRequestAnimationFrame'] ||
    mozRaf ||
    window['oRequestAnimationFrame'] ||
    window['msRequestAnimationFrame']);
const requestTimeoutNoRaf = (fn, delay) => {
  const timeoutId = setTimeout(fn, delay);
  return () => clearTimeout(timeoutId);
};
const requestTimeoutRaf = (fn, delay) => {
  const start = new Date().getTime();
  let rafId;
  const loop = () => {
    const delta = new Date().getTime() - start;
    if (delta >= (delay || 0)) {
      fn();
      rafId = undefined;
      return;
    }
    rafId = requestAnimationFrame(loop);
  };
  rafId = requestAnimationFrame(loop);
  return () => {
    rafId && cancelAnimationFrame(rafId);
  };
};
const requestTimeout = browserSupportsRaf ? requestTimeoutRaf : requestTimeoutNoRaf;

export { requestTimeout as r };
